.readmoreBtn{
    padding: 10px 20px ;
    background-color: #0480FC;
}

img{
    width: 100%;
    max-height: 50vh;
}

.content{
    line-height: 1.5em;
    height: 3em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%; 
}