@import '../../styles/index.scss';

// footer{
//     background: #e1e2e4ab;
//     // background: linear-gradient(90deg, rgba(230,238,250,1) 0%, rgba(243,244,246,1) 100%);
// }

ul {
    li {
        .footer-item {
            color: #fff;
        }
    }
}

.trademark{
    color: #000;
}