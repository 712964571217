.full_content {
    h1{
        font-size: 3em;
        line-height: 1.2em;
    } 

    h2{
        font-size: 1.8em;
        line-height: 1.2em;
    } 

    h3{
        font-size: 1.2em;
        line-height: 1.2em;
    } 

    ul{
        list-style: disc;
        margin: 30px;

        li{
            color: rgb(87, 86, 86);

            h1{
                font-size: 3em;
                line-height: 1.2em;
            } 
        
            h2{
                font-size: 1.8em;
                line-height: 1.2em;
            } 
        
            h3{
                font-size: 1.2em;
                line-height: 1.2em;
            } 
        }

        a {
            text-decoration: double;
            color: #0580facc;
        }

            a:link {
                color: #0580facc;
            }
          
          /* visited link */
          a:visited {
            color: #2f2feb;
          }
          
          /* mouse over link */
          a:hover {
            color: rgb(37, 37, 240);
          }
    }

    p {
        ul{
            list-style: disc;
            margin: 30px;
    
            li{
                color: rgb(87, 86, 86);

                h1{
                    font-size: 3em;
                    line-height: 1.2em;
                } 
            
                h2{
                    font-size: 1.8em;
                    line-height: 1.2em;
                } 
            
                h3{
                    font-size: 1.2em;
                    line-height: 1.2em;
                } 
            }
    
            a {
                text-decoration: double;
                color: #0580facc;
            }
    
                a:link {
                    color: #0580facc;
                }
              
              /* visited link */
              a:visited {
                color: #2f2feb;
              }
              
              /* mouse over link */
              a:hover {
                color: rgb(37, 37, 240);
              }
        }  

        a {
            text-decoration: double;
            color: #0580facc;
        }

            a:link {
                color: #0580facc;
            }
          
          /* visited link */
          a:visited {
            color: #2f2feb;
          }
          
          /* mouse over link */
          a:hover {
            color: rgb(37, 37, 240);
          }

          h1{
            font-size: 3em;
            line-height: 1.2em;
        } 
    
        h2{
            font-size: 1.8em;
            line-height: 1.2em;
        } 
    
        h3{
            font-size: 1.2em;
            line-height: 1.2em;
        } 
    }
 
    a {
        text-decoration: double;
        color: #0580facc;
    }

        a:link {
            color: #0580facc;
        }
      
      /* visited link */
      a:visited {
        color: #2f2feb;
      }
      
      /* mouse over link */
      a:hover {
        color: rgb(37, 37, 240);
      }

}

