.full_content {
  /* visited link */
  /* mouse over link */
}
.full_content h1 {
  font-size: 3em;
  line-height: 1.2em;
}
.full_content h2 {
  font-size: 1.8em;
  line-height: 1.2em;
}
.full_content h3 {
  font-size: 1.2em;
  line-height: 1.2em;
}
.full_content ul {
  list-style: disc;
  margin: 30px;
  /* visited link */
  /* mouse over link */
}
.full_content ul li {
  color: rgb(87, 86, 86);
}
.full_content ul li h1 {
  font-size: 3em;
  line-height: 1.2em;
}
.full_content ul li h2 {
  font-size: 1.8em;
  line-height: 1.2em;
}
.full_content ul li h3 {
  font-size: 1.2em;
  line-height: 1.2em;
}
.full_content ul a {
  -webkit-text-decoration: double;
          text-decoration: double;
  color: rgba(5, 128, 250, 0.8);
}
.full_content ul a:link {
  color: rgba(5, 128, 250, 0.8);
}
.full_content ul a:visited {
  color: #2f2feb;
}
.full_content ul a:hover {
  color: rgb(37, 37, 240);
}
.full_content p {
  /* visited link */
  /* mouse over link */
}
.full_content p ul {
  list-style: disc;
  margin: 30px;
  /* visited link */
  /* mouse over link */
}
.full_content p ul li {
  color: rgb(87, 86, 86);
}
.full_content p ul li h1 {
  font-size: 3em;
  line-height: 1.2em;
}
.full_content p ul li h2 {
  font-size: 1.8em;
  line-height: 1.2em;
}
.full_content p ul li h3 {
  font-size: 1.2em;
  line-height: 1.2em;
}
.full_content p ul a {
  -webkit-text-decoration: double;
          text-decoration: double;
  color: rgba(5, 128, 250, 0.8);
}
.full_content p ul a:link {
  color: rgba(5, 128, 250, 0.8);
}
.full_content p ul a:visited {
  color: #2f2feb;
}
.full_content p ul a:hover {
  color: rgb(37, 37, 240);
}
.full_content p a {
  -webkit-text-decoration: double;
          text-decoration: double;
  color: rgba(5, 128, 250, 0.8);
}
.full_content p a:link {
  color: rgba(5, 128, 250, 0.8);
}
.full_content p a:visited {
  color: #2f2feb;
}
.full_content p a:hover {
  color: rgb(37, 37, 240);
}
.full_content p h1 {
  font-size: 3em;
  line-height: 1.2em;
}
.full_content p h2 {
  font-size: 1.8em;
  line-height: 1.2em;
}
.full_content p h3 {
  font-size: 1.2em;
  line-height: 1.2em;
}
.full_content a {
  -webkit-text-decoration: double;
          text-decoration: double;
  color: rgba(5, 128, 250, 0.8);
}
.full_content a:link {
  color: rgba(5, 128, 250, 0.8);
}
.full_content a:visited {
  color: #2f2feb;
}
.full_content a:hover {
  color: rgb(37, 37, 240);
}/*# sourceMappingURL=style.css.map */