@import "../../../styles/index.scss";

// .bg-color{
//    background-color: $navbar-background;
// }
$color: #000;
$bg-color: #fff;
$btn-bg: #0480FC;

.navbar {
  background-color: $bg-color;
}

img{
  color: $navbar-font-color;
}

ul {
   li{
      a {
        color: $color;
      }
   }
}

.logo-text{
  color: $color;
}

.sign-ups{
  border: 1px solid $color;
  color: $bg-color;
  background-color: $btn-bg;
  border: none
}

.sign-ups:hover{
  background-color: $bg-color;
  color: $btn-bg;
}

.lists{
  background: rgb(230,238,250);
  background: linear-gradient(90deg, rgba(230,238,250,1) 0%, rgba(243,244,246,1) 100%);
}